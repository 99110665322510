@import 'react-credit-cards-2/dist/es/styles-compiled.css';
.glassMagnifier {
    max-width: 80%;
    margin: 0 auto;
}

.glassMagnifier > div > div > img {
    max-width: none;
}

.dataPicker {
    width: 100%;
    height: 3rem;
}

.react-datepicker-popper {
    z-index: 999 !important;
}

.w-85 {
    width: 21.5rem;
}
.w-91 {
    width: 22.8rem;
}

.react-datepicker {
    border: none !important;
}

.react-datepicker__header {
    background-color: white !important;
}

.react-datepicker__month-container {
    width: 225px !important;
}

.break-word {
	word-break: break-word !important;
}

.dataPicker-month {
    height: 2rem;
    box-sizing: border-box;
    -webkit-appearance: none;
    appearance: none;
    border-color: #C8CACE;
    border-radius: 0.125rem;
    padding: 0.5rem 0.75rem;
    line-height: 1.5;
    font-size: 15px;
    cursor: pointer;
}

.text-ruby-500 {
    --text-opacity: 1;
    color: #ff8282;
}

.border-ruby-500 {
    --border-opacity: 1;
    border-color: #ff8282;
}

.hover\:text-ruby-500:hover {
    --text-opacity: 1;
    color: #935757;
}

.hover\:border-ruby-500:hover {
    --bg-opacity: 1;
    border-color: #935757;
}

.bg-green-status {
    --border-opacity: 1;
    background-color: #019d47;
}

.bg-red-status {
    --border-opacity: 1;
    background-color: #e70124;
}

.bg-yellow-status {
    --border-opacity: 1;
    background-color: orange;
}

.bg-orchid-status {
    --border-opacity: 1;
    background-color: rgba(154, 204, 50, 0.47);
}

.bg-mantis-status {
    --border-opacity: 1;
    background-color: rgba(122, 175, 98, 0.47);
}

.bg-atlantis-status {
    --border-opacity: 1;
    background-color: rgba(147, 197, 75);
}

.bg-blue-status {
    --border-opacity: 1;
    border-color: rgba(25, 118, 210, 0.47);
}

.bg-bright-red-status {
    --border-opacity: 1;
    background-color: rgba(153, 33, 42, 0.47);
}

.bg-refunded-status {
    --border-opacity: 1;
    background-color: rgb(10, 215, 4);
}

.bg-refunded-status {
    --border-opacity: 1;
    background-color: rgb(178, 57, 6);
}

.button-text-red {
    --text-opacity: 1;
    color: #d50020;
}

.button-border-red {
    --border-opacity: 1;
    border-color: #d50020;
}

.hover\:button-border-red:hover {
    --bg-opacity: 1;
    background-color: #d50020;
}

.button-text-green {
    --text-opacity: 1;
    color: #06bb00;
}

.button-border-green {
    --border-opacity: 1;
    border-color: #06bb00;
}

.hover\:button-border-green:hover {
    --bg-opacity: 1;
    background-color: #06bb00;
}

.left-10 {
    left: 10px;
}

.filter-view {
    opacity: 1;
    /*transition: opacity 0.5s linear;*/
    transition: opacity 1s ease-in-out;
}

.filter-view.is-show {
    opacity: 0;
}

.filter-view-enter {
    opacity: 0;
}

.filter-view-enter-active {
    opacity: 1;
    transition: opacity 0.5s linear;
}

.filter-view-exit {
    opacity: 1;
}

.filter-view-exit-active {
    opacity: 0;
    transition: opacity 0.5s linear;
}

.item-background-color-blue {
    background: #1976d2;
}

.item-color-blue {
    color: #1976d2;
}

.item-border-color-blue {
    border-color: #b3d1ec;
}

.item-border-color-blue:hover {
    border-color: #4ca4ec;
}

.arial-font {
    font-family: Arial, Helvetica, sans-serif
}

.active-item {
    border-radius: 0 8px 8px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.logo-position {
    z-index: 123;
    top: 18px;
    right: 17px;
}

.box-bg-dark {
    background: linear-gradient(195deg, #42424a, #191919);
}

.box-bg-red-300 {
    background: linear-gradient(195deg, #EC407A, #D81B60);
}

.box-bg-red-400 {
    background: linear-gradient(195deg, #ee0e39, #bb0a37);
}

.box-bg-green-300 {
    background: linear-gradient(195deg, #66BB6A, #2c772f);
}

.box-bg-mantis-300 {
    background: linear-gradient(195deg, #85BB65, #6A9651);
}

.box-bg-cream-can {
    background: linear-gradient(195deg, #F0BD46, #C09738);
}

.box-bg-blue-300 {
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
}

.box-bg-valencia-300 {
    background: linear-gradient(195deg, #e07c74, #b05f5a);
}

.box-bg-valencia-400 {
    background: linear-gradient(195deg, #dc6e63, #bb514c);
}

.box-bg-valencia-300:hover {
    background: linear-gradient(195deg, #8D4C48, #ab5a57);
}

.box-bg-gossip-300 {
    background: linear-gradient(195deg, #98DB7A, #628C4E);
}

.box-bg-bright-red-300 {
    background: linear-gradient(195deg, #99212A, #62151B);
}

.box-bg-night-rider-300 {
    background: linear-gradient(195deg, #2B2B2B, #555555);
}

.box-bg-neon-blue-300 {
    background: linear-gradient(195deg, #5858fd, #4646c5);
}

.box-bg-neon-blue-400 {
    background: linear-gradient(195deg, #4e4efc, #4040cc);
}

.box-bg-primary-400 {
    background: linear-gradient(195deg, #1976D2, #145EA8);
}

.box-bg-neon-blue-300:hover {
    background: linear-gradient(195deg, #4d4de5, #3535c0);
}

.box-bg-bright-red-200 {
    background: linear-gradient(195deg, #ad2732, #62151B);
}

.box-bg-yellow-orange-300 {
    background: linear-gradient(195deg, #F7AC3C, #C68A30);
}

.box-bg-zest-300 {
    background: linear-gradient(195deg, #c54419, #d75128);
}

.box-bg-hippie-blue-300 {
    background: linear-gradient(195deg, #3A8696, #2E6B78);
}

.box-bg-yellow-green-300 {
    background: linear-gradient(195deg, #9ACC32, #628220);
}

.box-bg-brown-300 {
    background: linear-gradient(195deg, #A82E2E, #862525);
}

.box-bg-laser {
    background: linear-gradient(195deg, #CDAC60, #A48A4D);
}

.box-bg-roti-300 {
    background: linear-gradient(195deg, #d9be48, #9d8c37);
}

.box-bg-roti-400 {
    background: linear-gradient(195deg, #dcc147, #a29035);
}

.box-bg-roti-300:hover {
    background: linear-gradient(195deg, #bea739, #9a872d);
}

.box-bg-green-smoke-300 {
    background: linear-gradient(195deg, #A5AD5D, #6A6E3B);
}

.box-bg-green-smoke-400 {
    background: linear-gradient(195deg, #aab25e, #6A6E3B);
}

.box-bg-pine-glade-300 {
    background: linear-gradient(195deg, #BBC17E, #6A6E3B);
}

.box-bg-green-smoke-300:hover {
    background: linear-gradient(195deg, #969f45, #5c6229);
}

.box-bg-dodger-blue-300 {
    background: linear-gradient(195deg, #189af8, #0C4E7E);
}

.box-bg-Pale-cornflower-blue {
    background: linear-gradient(195deg, #B2D1ED, #8EA7BE);
}

.box-bg-nepal {
    background: linear-gradient(195deg, #8EA7BE, #728698);
}

.box-bg-curious-blue {
    background: linear-gradient(195deg, #3D85C6, #316A9E);
}

.box-bg-lochmara {
    background: linear-gradient(195deg, #316A9E, #27557E);
}

.box-bg-air-force-blue {
    background: linear-gradient(195deg, #5986B0, #476B8D);
}

.box-bg-medium-aquamarine {
    background: linear-gradient(195deg, #73C8A9, #5CA087);
}

.box-bg-tusk {
    background: linear-gradient(195deg, #DEE1B6, #B2B492);
}

.box-bg-bud {
    background: linear-gradient(195deg, #B2B492, #B2B492);
}

.box-bg-biloba-flower-300 {
    background: linear-gradient(195deg, #B7A6C7, #5E5566);
}

.box-bg-orange-300 {
    background: linear-gradient(195deg, #FFA500, #A36A00);
}

.box-bg-grey-300 {
    background: linear-gradient(195deg, #ADADAD, #6E6E6E);
}

.box-bg-nero-300 {
    background: linear-gradient(195deg, #292929, #545454);
}

.box-bg-blue-lagoon-300 {
    background: linear-gradient(195deg, #125368, #0E4253);
}

.box-bg-americano-300 {
    background: linear-gradient(195deg, #897C72, #593E2C);
}

.box-bg-california-300 {
    background: linear-gradient(195deg, #E69138, #B8742D);
}

.box-bg-roman-300 {
    background: linear-gradient(195deg, #E06666, #B35252);
}

.box-bg-geraldine {
    background: linear-gradient(195deg, #E57D7D, #B76464);
}

.box-bg-cinnabar {
    background: linear-gradient(195deg, #D84242, #AD3535);
}

.box-bg-white-300 {
    background: linear-gradient(195deg, #ffffff, #eaeaea);
}

.box-bg-dark-red-300 {
    background: linear-gradient(195deg, #990000, #7A0000);
}

.box-bg-dark-red-400 {
    background: linear-gradient(195deg, #FF0000, #7A0000);
}

.box-bg-info-400 {
    background: #E1F3F8;
}

.box-bg-error-400 {
    background: #f9dede;
}

.box-bg-warning-400 {
    background: #FFF1D2;
}

.box-bg-success-400 {
    background: #E7F4E4;
}

.box-shadow-dark {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(64 64 64 / 40%);
}

.box-shadow-dark-red {
    box-shadow: 0 0.25rem 1.25rem 0 rgb(0 0 0 / 14%);
}

.box-shadow-red-400 {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(255 0 0 / 40%);
}

.box-shadow-night-rider {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(85 85 85 / 40%);
}

.box-shadow-red {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(233 30 98 / 40%);
}

.box-shadow-nepal {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(142 167 190 / 40%);
}

.box-shadow-curious-blue {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(61 133 198 / 40%);
}

.box-shadow-air-force-blue {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(89 134 176 / 40%);
}

.box-shadow-lochmara {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(49 106 158 / 40%);
}

.box-shadow-medium-aquamarine {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(115 200 169 / 40%);
}

.box-shadow-laser {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(205 172 96 / 40%);
}

.box-shadow-tusk {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(222 225 182 / 40%);
}

.box-shadow-bud {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(178 180 146 / 40%);
}

.box-shadow-green {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(76 175 79 / 40%);
}

.box-mantis-green {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(133 187 101 / 40%);
}

.box-shadow-blue {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%);
}

.box-cream-can {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(240 189 70 / 40%);
}

.box-shadow-primary {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(25 118 210 / 40%);
}

.box-shadow-valencia {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(232 80 80 / 40%);
}

.box-shadow-neon-blue {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(80 80 232 / 40%);
}

.box-shadow-hippie-blue {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(98 130 32 / 40%);
}

.box-shadow-brown {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(168 46 46 / 40%);
}

.box-shadow-roti {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(246 216 78 / 40%);
}

.box-shadow-green-smoke {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(165 173 93 / 40%);
}

.box-shadow-dodger-blue {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(24 154 248 / 40%);
}

.box-shadow-biloba-flower {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(94 85 102 / 40%);
}

.box-shadow-orange {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(255 165 0 / 40%);
}

.box-shadow-grey {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(173 173 173 / 40%);
}

.box-shadow-nero {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(84 84 84 / 40%);
}

.box-shadow-california {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(230 145 56 / 40%);
}

.box-shadow-roman {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(224 102 102 / 40%);
}

.box-shadow-dark-red {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(153 0 0 / 40%);
}

.box-shadow-blue-lagoon {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(18 83 104 / 40%);
}

.box-shadow-americano {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(137 125 114 / 40%);
}

.box-shadow-gossip {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(152 219 122 / 40%);
}

.box-shadow-bright-red {
    box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(153 33 42 / 40%);
}

.statistic-card {
    width: 16.5rem;
    height: 8.5rem;
}

hr.style-hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(192, 190, 190, 0.75), rgba(0, 0, 0, 0));
}

.form-input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #C8CACE;
  border-width: 1px;
  border-radius: 0.125rem;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
	transition: all 0.3s;
  color: #2F323A;
  height: 2.25rem;
  font-weight: 500;
}

.form-input:focus {
  outline: none;
  box-shadow: 0 0 2px 3px rgba(21, 123, 250, 0.4);
  border-color: #157BFA !important;
  background: #fff;
}

.col-gap-2 {
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #a0aec0 !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #a0aec0 !important;
}

input::placeholder,
textarea::placeholder {
  color: #a0aec0 !important;
}

.step-wizard-list{
    color: #333;
    list-style-type: none;
    border-radius: 10px;
    display: flex;
    z-index: 10;
}

.step-wizard-item {
    padding: 0 5px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive:1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
}

@media (min-width: 769px) {
  .step-wizard-item {
		padding: 0 28px;
  }
}

.step-wizard-item + .step-wizard-item:after {
    content: "";
    position: absolute;
    left: 0;
    top: 19px;
    background: #157BFA;
    width: 100%;
    height: 2px;
    transform: translateX(-50%);
    z-index: -10;
}
.progress-count{
    height: 40px;
    width:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    z-index:10;
    color: transparent;
}
.progress-count:after{
    content: "";
    height: 40px;
    width: 40px;
    background: #157BFA;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
}
.progress-count:before{
    content: "";
    height: 10px;
    width: 20px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
    transform-origin: center center;
}
.progress-label{
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}
.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before{
    display: none;
}
.current-item ~ .step-wizard-item .progress-count:after{
    height:10px;
    width:10px;
}

.current-item ~ .step-wizard-item .progress-label{
    opacity: 0.5;
}
.current-item .progress-count:after{
    background: #fff;
    border: 2px solid #157BFA;
}
.current-item .progress-count{
    color: #157BFA;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar:horizontal {
  height: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e5e7eb;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 8px;
  background-clip: padding-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

.custom-scrollbar {
  scrollbar-width: thin;
}

.rccs__card {
    width: 350px!important;
    height: 220px!important;
}

.rcsc {
    margin: 0 auto;
    perspective: 1000px;
    width: 290px;
}

.rcsc-card {
    height: 220px;
    margin: 0 auto;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 350px;
}

.fade-out {
    transition: opacity 1s ease-in-out;
}

.text-gray-Shuttle {
    color: rgb(85, 94, 104);
}